<template>
    <div class="layout">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.layout {
    width: 100vw;
    height: 100vh;
}
</style>
